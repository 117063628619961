import { createStore } from 'vuex'

export default createStore({
  state: {
    count: 0
  },
  mutations: {
    INCREMENT_COUNT(state) {
      state.count += 1
    }
  },
  actions: {
    incrementCount(context) {
        context.commit('INCREMENT_COUNT')
    }
  },
  getters: {
    getCount: state => {
      return state.count
    }
  }
})
