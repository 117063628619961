<template>
  <div>
    <p>{{getCount}}</p>
    <button type="button" @click="doIncrement">increment</button>
  </div>
</template>

<script>
export default {
  name: 'VuexPlayground',
  methods: {
    doIncrement: function () {
      this.$store.dispatch('incrementCount')
    }
  },
  computed: {
    getCount: function () {
      return this.$store.getters.getCount
    }
  }
}
</script>

<style>

</style>